<template lang="pug">
p(v-if='shippingMessage')
  | {{ shippingMessage }} #[span(v-if='timeRemaining' class='tabular-nums font-bold whitespace-nowrap') {{ timeRemaining }}]
</template>

<script setup lang="ts">
const { date, hasFreeShipping, showShipDate } = defineProps<{
  date: string
  hasFreeShipping?: boolean
  showShipDate?: boolean
}>()

const emit = defineEmits<{
  refresh: []
}>()

const { now, pause, resume } = useNow({ controls: true, interval: 1000 })

const shipDate = computed(() => {
  return new Date(date)
})

const shipDateMs = computed(() => {
  return shipDate.value.getTime()
})

const shipDateDate = computed(() => {
  return shipDate.value.getDate()
})

const formattedShipDate = computed(() => {
  return shipDate.value.toLocaleDateString(undefined, {
    weekday: 'long',
  })
})

const timeRemaining = ref('')
const shippingMessage = ref('')

watchEffect(() => {
  // Start timer. We have to run this eventhough its started by default
  resume()

  // Amount of time between the ship date and now
  const msRemaining = shipDateMs.value - now.value.getTime()

  // If the date is in the future, update the messaging
  if (msRemaining > 0) {
    const isToday = now.value.getDate() === shipDateDate.value

    const hours = Math.floor(msRemaining / 1000 / 60 / 60)
    const minutes = Math.floor((msRemaining / 1000 / 60) % 60)
    const seconds = Math.floor((msRemaining / 1000) % 60)

    let message = ''

    // If we should show the ship date
    if (showShipDate) {
      const shipDay = isToday ? 'today' : formattedShipDate.value

      const shipText = `Ships ${shipDay}`

      message = hasFreeShipping ? `Free Shipping - ${shipText}` : shipText
    }

    // Only render the timer if we are less than 24 hours away
    if (hours < 24) {
      // Format the numbers and set the time remaining message
      const hoursFormatted = hours ? `${hours.toString().padStart(2, '0')}h` : ''
      const minutesFormatted = `${minutes.toString().padStart(2, '0')}m`
      const secondsFormatted = `${seconds.toString().padStart(2, '0')}s`

      timeRemaining.value = `${hoursFormatted} ${minutesFormatted} ${secondsFormatted}`.trim()
      message = message ? `${message}, order within` : 'Order within'
    }

    // Set the message
    shippingMessage.value = message
  } else {
    // Pause the timer
    pause()

    // Notify that the timer has finished
    emit('refresh')
  }
})
</script>
