<template lang="pug">
Tooltip(mode='click' :is-full-width='!$device.isDesktop')
  InfoIcon(class='w-4 h-4 cursor-pointer text-action fill-current align-middle focus:outline-none')
  template(#text)
    div(class='inline space-y-3')
      p This estimate is based on various factors such as purchase date, location, handling times, shipping carrier, and holidays. These potential factors could delay the arrival of your item.

      p(class='font-bold text-xl') Shipping Steps

      div(class='grid grid-flow-col gap-x-1')
        div
          p(class='font-bold text-2xl') 1
          p(class='font-bold') {{ now }}
          p Ordered

        div(class='text-center')
          p(class='font-bold text-2xl') 2
          p(class='font-bold') {{ formattedShipDate }}
          p Shipped

        div(class='text-right')
          p(class='font-bold text-2xl') 3
          p(class='font-bold') {{ formattedEstimatedDeliveryDate }}
          p Delivered
</template>

<script setup lang="ts">
import InfoIcon from '@/assets/info.svg?component'

const { $device } = useNuxtApp()

const { shipDate, estimatedDeliveryDate } = defineProps<{
  shipDate: string
  estimatedDeliveryDate: string
}>()

const now = computed(() =>
  new Date().toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: '2-digit' })
)

const formattedShipDate = computed(() =>
  new Date(shipDate).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: '2-digit' })
)

const formattedEstimatedDeliveryDate = computed(() =>
  new Date(estimatedDeliveryDate).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric', year: '2-digit' })
)
</script>
