<template lang="pug">
//- NOTE: relative class must be here for the tool tip to work, otherwise it will not show on mobile.
div(
  v-if='shippingTitle || data.estimatedDeliveryDate || ($sitewideConfig.config.sameDayShippingEnabled && data.shipDate)'
  :class='{ "flex gap-x-4 items-center": showIcon }'
)
  div(v-if='showIcon')
    SameDayShippingLogo(
      v-if='$sitewideConfig.config.sameDayShippingEnabled && data.shipMethod === "EXPEDITED"'
      class='w-8 h-auto'
      is-icon
    )

    div(v-else-if='data.shipMethod === "FREIGHT" && data.isEligibleFreeShipping')
      FreightBox(class='w-8 h-auto text-action-dark stroke-current fill-current')

    div(v-else class='flex justify-center items-center rounded-full bg-success w-8 h-8')
      img(src='/images/checkmarkThick.svg' alt='checkmark' class='w-5 h-5')

  div(class='flex-1')
    div(v-if='shippingTitle' class='relative flex items-center gap-x-1')
      p(class='font-bold') {{ shippingTitle }}
      ShippingSameDayTooltip(
        v-if='$sitewideConfig.config.sameDayShippingEnabled && data.shipDate && data.estimatedDeliveryDate'
        :ship-date='data.shipDate'
        :estimated-delivery-date='data.estimatedDeliveryDate'
      )

    ShippingEstimatedDeliveryDate(v-if='data.estimatedDeliveryDate' :date='data.estimatedDeliveryDate')

    ShippingShipDate(
      v-if='$sitewideConfig.config.sameDayShippingEnabled && data.shipDate'
      :date='data.shipDate'
      :show-ship-date='!data.estimatedDeliveryDate'
      :has-free-shipping='data.isEligibleFreeShipping'
      @refresh='emit("refresh")'
    )

    slot
</template>

<script setup lang="ts">
import FreightBox from '@/assets/freightBox.svg?component'

const { data, showIcon, title } = defineProps<{
  data: Shipping
  showIcon?: boolean
  title?: string
}>()

// When the shipDate timer completes we need to emit up the refresh event so the thing that passed in the data
// knows to refresh
const emit = defineEmits<{
  refresh: []
}>()

const { sameDayShippingText } = useSameDayShippingDisplay()

const shippingTitle = computed(() => {
  // Override the title
  if (title) return title

  switch (data.shipMethod) {
    case 'EXPEDITED':
      return data.isEligibleFreeShipping && data.estimatedDeliveryDate
        ? 'Free Delivery'
        : `${sameDayShippingText} Ready`

    case 'STANDARD':
      return data.isEligibleFreeShipping ? 'Free Shipping' : ''

    case 'FREIGHT':
      return data.isEligibleFreeShipping ? 'Free Freight Shipping' : ''

    default:
      return ''
  }
})
</script>
